import { useApolloClient, useMutation } from "@apollo/client";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomizedDialogs from "../../components/Dialog";
import { uploadFile } from "../../utils/uploadFIle";

import {
  Paper,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Chip,
} from "@material-ui/core";

import { AddCircleOutlineRounded } from "@material-ui/icons";
import { NotificationManager } from "react-notifications";
import { EDIT_PROJECT } from "../../graphql/mutation";

export default function EditProject({ refetch, projectId, project, onClose }) {
  const [name, setName] = useState(project.name);
  const [description, setDescription] = useState(project.description);
  const [file, setFile] = useState([]);
  const [successText, setSuccessText] = useState("");
  const [validationError, setValidationError] = useState("");

  const [isPublic, setIsPublic] = useState(project.isPublic);
  const [tags, setTags] = useState(
    project.tags.split(",").filter((item) => !!item)
  );
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [editProject, { loading, error }] = useMutation(EDIT_PROJECT);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileChange = (event) => {
    // Handle file input if needed
    let files = [];
    for (var i = 0; i < event.target.files.length; i++) {
      files.push(event.target.files[i]);
    }
    setFile((prev) => [...files]);
  };
  const handleCreateProject = async () => {
    if (!name) {
      setValidationError("Project Name is required");
      return;
    }
    if (!description) {
      setValidationError("Decription is required");
      return;
    }

    setValidationError("");
    let fileUploadedUrl = project.logo;
    if (file.length == 0) {
      fileUploadedUrl = await uploadFilesAndGetUrl();
    }

    try {
      const { data } = await editProject({
        variables: {
          input: {
            id: parseInt(projectId),
            name,
            description,
            logo: fileUploadedUrl,
            isPublic,
            tags: tags.join(","),
            // Include other variables needed for project creation
          },
        },
      });

      NotificationManager.success("Projetc has been edited successfully");
      refetch();
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  const uploadFilesAndGetUrl = async () => {
    let fileUploadUrl = "";
    setFileUploadLoading(true);
    for (const f of file) {
      const uploaded = await uploadFile(f);
      fileUploadUrl =
        fileUploadUrl == "" ? uploaded : `${fileUploadUrl},${uploaded}`;
    }
    setFileUploadLoading(false);
    return fileUploadUrl;
  };

  return (
    <CustomizedDialogs
      open={true}
      onClose={onClose}
      loading={loading || fileUploadLoading}
      success={successText}
      error={error?.message || validationError}
      title="Edit Project"
      handleSaveClick={() => {
        handleCreateProject();
      }}
      content={
        <>
          <CreateProject
            name={name}
            isPublic={isPublic}
            tags={tags}
            setTags={setTags}
            setIsPublic={setIsPublic}
            description={description}
            handleNameChange={handleNameChange}
            handleDescriptionChange={handleDescriptionChange}
            handleFileChange={handleFileChange}
          />
        </>
      }
    />
  );
}

// Import your GraphQL mutation

function CreateProject({
  name,
  handleNameChange,
  description,
  handleDescriptionChange,
  handleFileChange,
  isPublic,
  setIsPublic,
  tags,
  setTags,
}) {
  const [tagText, setTagText] = useState("");

  return (
    <Paper>
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Name"
        type="text"
        variant="outlined"
        value={name}
        onChange={handleNameChange}
      />
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        multiline
        minRows={4}
        id="outlined-basic"
        label="Description"
        type="text"
        variant="outlined"
        value={description}
        onChange={handleDescriptionChange}
      />
      <div style={{ marginBottom: "1rem" }}>
        <div style={{ display: "flex" }}>
          <TextField
            style={{ flex: 1 }}
            fullWidth
            id="outlined-basic"
            label="Tags"
            type="text"
            variant="outlined"
            value={tagText}
            onChange={(e) => setTagText(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={() => {
              setTags((prev) => [...prev, tagText]);
              setTagText("");
            }}
            color="primary"
            endIcon={<AddCircleOutlineRounded />}
            style={{ marginLeft: "0.5rem", height: "auto" }}
          >
            Add
          </Button>
        </div>
        <ul
          component="ul"
          style={{
            marginTop: "0.5rem",
            listStyle: "none",
            paddingLeft: 0,
            display: "flex",
          }}
        >
          {tags.map((data) => {
            return (
              <li style={{ marginRight: "0.3rem" }} key={data.key}>
                <Chip label={data} />
              </li>
            );
          })}
        </ul>
      </div>
      <input
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
          marginBottom: "1rem",
        }}
        type="file"
        onChange={handleFileChange}
      />

      <FormControlLabel
        style={{ marginBottom: "1rem" }}
        labelPlacement="start"
        control={
          <Switch
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label="Public"
      />
    </Paper>
  );
}
