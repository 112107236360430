import React, { useState } from "react";
import {
  Paper,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Chip,
} from "@material-ui/core";

import { AddCircleOutlineRounded } from "@material-ui/icons";
// Import your GraphQL mutation

export default function CreateProject({
  name,
  handleNameChange,
  description,
  handleDescriptionChange,
  handleFileChange,
  isPublic,
  setIsPublic,
  tags,
  setTags,
}) {
  const [tagText, setTagText] = useState("");

  return (
    <Paper>
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        id="outlined-basic"
        label="Name"
        type="text"
        variant="outlined"
        value={name}
        onChange={handleNameChange}
      />
      <TextField
        style={{ marginBottom: "1rem" }}
        fullWidth
        multiline
        minRows={4}
        id="outlined-basic"
        label="Description"
        type="text"
        variant="outlined"
        value={description}
        onChange={handleDescriptionChange}
      />
      <div style={{ marginBottom: "1rem" }}>
        <div style={{ display: "flex" }}>
          <TextField
            style={{ flex: 1 }}
            fullWidth
            id="outlined-basic"
            label="Tags"
            type="text"
            variant="outlined"
            value={tagText}
            onChange={(e) => setTagText(e.target.value)}
          />
          <Button
            variant="contained"
            onClick={() => {
              setTags((prev) => [...prev, tagText]);
              setTagText("");
            }}
            color="primary"
            endIcon={<AddCircleOutlineRounded />}
            style={{ marginLeft: "0.5rem", height: "auto" }}
          >
            Add
          </Button>
        </div>
        <ul
          component="ul"
          style={{
            marginTop: "0.5rem",
            listStyle: "none",
            paddingLeft: 0,
            display: "flex",
          }}
        >
          {tags.map((data) => {
            return (
              <li style={{ marginRight: "0.3rem" }} key={data.key}>
                <Chip label={data} />
              </li>
            );
          })}
        </ul>
      </div>
      <input
        style={{
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          padding: "1rem",
          marginBottom: "1rem",
        }}
        type="file"
        onChange={handleFileChange}
      />

      <FormControlLabel
        style={{ marginBottom: "1rem" }}
        labelPlacement="start"
        control={
          <Switch
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            color="primary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label="Public"
      />
    </Paper>
  );
}
